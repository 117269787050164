<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <TablesAntdBasic/>
      </div>
    </div>
  </div>
</template>

<script>
// import { Modal } from 'ant-design-vue'
import TablesAntdBasic from './table/index'

/* Kolom atau header dari table */
/* End script Kolom atau header dari table */

export default {
  components: {
    TablesAntdBasic,
  },
}
</script>
